<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                defaultHref="/"
                (click)="goBack()"
                text=""
            ></ion-back-button>
        </ion-buttons>
        <ion-title (click)="goBack()"></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="isDataLoaded">
    <div class="wrapper-div margin-top">
        <div class="content-wrap">
            <h2 class="instructions-title">
                {{'manual-changes.instructions' | translate}}
            </h2>
            <p class="instructions-version">
                {{'manual-changes.version' | translate : {"version":
                manual.version} }}
            </p>
        </div>

        <div class="content-wrap">
            <div class="info-section">
                <p>
                    {{'manual-changes.changes-label' | translate : {"date":
                    getPublishDate} }}
                </p>
            </div>
        </div>

        <ab-component
            *ngFor="let component of manualChangesComponents"
            [component]="component"
        ></ab-component>

        <div class="content-wrap">
            <ion-button expand="full" (click)="approveVersion()">
                {{'manual-changes.accept' | translate}}
            </ion-button>
        </div>

        <div class="content-wrap" *ngIf="contactPerson">
            <div class="linked-profile" (click)="authorDetails(contactPerson)">
                <h2>{{'manual-changes.question' | translate}}</h2>
                <p>
                    {{'manual-changes.question-label' | translate : {'name':
                    contactPerson.title} }}
                </p>
                <div class="profile-card">
                    <img
                        src="../assets/images/default-account.png"
                        alt="{{ contactPerson.name }}"
                    />
                    <div class="column text">
                        <span class="name">{{ contactPerson.title }}</span>
                        <span class="label">
                            {{ 'manual-changes.view-profile' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
