import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NavController } from "@ionic/angular";
import { API_URL } from "../../../environments/environment";
import moment from "moment";
import { ManualsService } from "../../services/manuals/manuals.service";

@Component({
    selector: "app-manual-changes",
    templateUrl: "./manual-changes.page.html",
    styleUrls: ["./manual-changes.page.scss"]
})
export class ManualChangesPage implements OnInit {
    public manual;
    public readonly imagePath;
    private readonly manualId;
    public contactPerson = null;
    public isDataLoaded = false;
    public manualChangesComponents = [];

    constructor(
        private manualsService: ManualsService,
        private route: ActivatedRoute,
        private navController: NavController
    ) {
        this.manualId = parseInt(this.route.snapshot.params.id, 0);
        this.imagePath = API_URL.replace("api", "files/translations/");
        this.listenForWysiwygLinks();
    }

    ngOnInit() {
        this.manualsService
            .fetchManualChapters(this.manualId)
            .subscribe((data) => {
                this.manual = data;

                if (!!this.manual.contact_person) {
                    this.contactPerson =
                        Object.keys(this.manual.contact_person).length === 0 &&
                        this.manual.contact_person.constructor === Object
                            ? null
                            : this.manual.contact_person;
                }

                this.manualsService
                    .fetchManualChanges(data.manual_id, data.id)
                    .subscribe((changesData) => {
                        this.manualChangesComponents =
                            changesData.adjustment.components;
                        this.isDataLoaded = true;
                    });
            });
    }

    authorDetails(author) {
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    goBack() {
        this.navController.pop();
    }

    get getPublishDate() {
        moment.locale("nl");
        return moment(this.manual.accept_before).format("dddd D MMM YYYY");
    }

    approveVersion() {
        this.manualsService
            .approveVersion(this.manual.manual_id, this.manual.id)
            .subscribe((data) => {
                this.navController.navigateForward([
                    `/manuals/${this.manual.manual_id}`
                ]);
            });
    }

    listenForWysiwygLinks() {
        document.addEventListener("click", (e) => {
            const el = e.target as HTMLAnchorElement;
            const dataset = el.dataset;

            if (dataset.redactorType === "articlelink" && dataset.link) {
                this.navController.navigateForward([`/news/${dataset.link}`]);
            }

            if (dataset.articleChapterLink && dataset.manualId) {
                this.navController.navigateForward(
                    [`/manuals/${dataset.manualId}`],
                    { queryParams: { scrollTo: dataset.articleChapterLink } }
                );
            }
        });
    }
}
